@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@700&family=Open+Sans&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Material+Symbols+Outlined:opsz,wght,FILL,GRAD@48,400,0,0');

@tailwind base;
@tailwind components;
@tailwind utilities;

html, body {
  height: 100%;
  overflow: hidden;
}
body {
  margin: 0;

  h1, p {
    padding: 0;
    margin: 0;
  }

  p, input {
    font-family: 'Open Sans', sans-serif;
  }

  input::placeholder {
    font-style: italic;
  }

  h1, h2 {
    font-weight: 700;
    font-family: 'Montserrat', sans-serif;
  }

  .tree-display-panel, .sensor-filters-menu {
    max-width: unset;
  }
}
